export const config = {
  firebaseSettings: {
    apiKey: 'AIzaSyB5J-4ANPidBHK8_7fuBVPoHitxK9GPYhk',
    authDomain: 'gull-vue.firebaseapp.com',
    databaseURL: 'https://gull-vue.firebaseio.com',
    projectId: 'gull-vue',
    storageBucket: '',
    messagingSenderId: '418473219984',
    appId: '1:418473219984:web:83d917d20e29e506',
  },
  roles: [
    'student', 'teacher', 'parent', 'company', 'admin', 'teacher_admin',
  ],
  presenceSettings: {
    presenceType: {
      absent: {
        id: 1,
        label: 'X',
        icon: 'mdi-close-circle',
      },
      present: {
        id: 2,
        label: 'O',
        icon: 'mdi-check-circle',
      },
    },
    defaultType: 'present'
  },
  presenceType: {
    present: 'O',
    absent: 'X',
  },
  presenceTypeID: {
    present: 2,
    absent: 1,
  },
  generalValues: {
    minWorkload: 0,
    maxWorkload: 30,
    passwordConditions: {
      minPasswordLength: 13,
      passwordPolicySpecial: true,
      passwordPolicyNumber: true,
      passwordPolicyUpper: true,
      passwordPolicyLower: true
    }
  },
  components: {
    presence: {
      type: [
        {
          i_PresenceTypeID: 1,
          s_Name: 'X',
          default: false
        },
        {
          i_PresenceTypeID: 2,
          s_Name: 'O',
          default: true
        }
      ]
    },
    availabilityCalendar: {
      type: {
        yes: 4,
        maybe: 5,
        no: 6,
        null: 0
      }
    },
    documentList: {
      type: {
        general: 68,
        location: 71,
        dates: 72,
        gridWriting: 73,
        gridOral: 74,
        examWriting: 69,
        examOral: 70
      }
    },
    catalog: {
        categoryIcons: {
          color: {
            white: 55,
            black: 56,
            cat: 57
          }
       }
    }
  }
}
